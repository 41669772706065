import { IconButton, InputAdornment, TextField } from "@mui/material"
import useDebounceSearch from "hooks/useDebounceSearch"
import React from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"

const ContractNumberFilter = () => {
	const { t } = useTranslation()
	const [homeNumber, setHomeNumber] = useDebounceSearch("contract_id", "", 300)

	return (
		<NumericFormat
			id="contract_id"
			name="contract_id"
			label={t("common.table.contractNumber")}
			value={homeNumber}
			onInput={(event) => setHomeNumber(event.target?.value)}
			color="formColor"
			variant="outlined"
			fullWidth
			customInput={TextField}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end" className="custom-endAdornment">
						{homeNumber && (
							<IconButton onClick={() => setHomeNumber("")} variant="onlyIcon">
								<i className="bi bi-x end-adornment-close-button" />
							</IconButton>
						)}
					</InputAdornment>
				)
			}}
			allowNegative={false}
			decimalScale={0}
		/>
	)
}

export default ContractNumberFilter
