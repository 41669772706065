import moment from "moment"
import template from "../template/Payment.docx"
import "moment/locale/ru"
import { numericFormatter } from "react-number-format"
import Docxtemplater from "docxtemplater"
import PizZip from "pizzip"
import { saveAs } from "file-saver"
const localeMoment = moment
localeMoment.locale("ru")

const generateChequeFromData = async ({
	paymentId,
	IsSubmittingFn = () => {},
	axiosPrivate = () => {},
	sendNotification = () => {}
}) => {
	if (paymentId) {
		try {
			IsSubmittingFn(true)
			const response = await axiosPrivate.get(
				`/dictionary/payment/${paymentId}`,
				{
					headers: { "Content-Type": "application/json" }
				}
			)
			if (response.data && response.data.status && response.data.data) {
				await generateDocument(response.data.data)
				IsSubmittingFn(false)
			}
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
			IsSubmittingFn(false)
		}
	}
}
const generateDocument = async (responseData) => {
console.log(responseData?.payment);
	let templateData = {
		company:
			responseData?.payment?.contract?.homes?.blocks?.objects?.companies?.name,
		id: responseData?.payment?.payment_number,
		date:
			responseData?.payment?.date &&
			localeMoment(responseData?.payment?.date).format("DD"),
		month:
			responseData?.payment?.date &&
			localeMoment(responseData?.payment?.date).format("MMMM"),
		year:
			responseData?.payment?.date &&
			localeMoment(responseData?.payment?.date).format("YYYY"),
		custom: `${responseData?.payment?.contract2?.custom?.surname} ${responseData?.payment?.contract2?.custom?.name} ${responseData?.payment?.contract2?.custom?.middlename}`,
		contractName: responseData?.payment?.contract?.name,
		objectName: responseData?.payment?.contract?.homes?.blocks?.objects?.name,
		blockName: responseData?.payment?.contract?.homes?.blocks?.name,
		homeNumber: responseData?.payment?.contract?.homes?.number,
		paymentPrice: responseData?.payment?.sum
			? numericFormatter(responseData?.payment?.sum, {
					decimalScale: 3,
					thousandSeparator: " ",
					allowNegative: false
			  })
			: "0",
		paymentPriceText: responseData?.numbertext,
		paymentType: responseData?.payment?.types?.name
	}
	try {
		let response = await fetch(template)
		let data = await response.arrayBuffer()

		let zip = PizZip(data)

		let templateDoc = new Docxtemplater(zip, {
			paragraphLoop: true,
			linebreaks: true
		})

		templateDoc.render(templateData)

		let generatedDoc = templateDoc.getZip().generate({
			type: "blob",
			mimeType:
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			compression: "DEFLATE"
		})

		saveAs(generatedDoc, `To'lov.docx`)
	} catch (error) {
		console.log("Error: " + error)
	}
}

export default generateChequeFromData
