import { Button, CircularProgress } from "@mui/material"
import SearchInput from "components/SearchInput"
import SimpleSearchTable from "components/ui/tables/SimpleSearchTable"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ContractFiltersComponent from "components/ui/filters/ContractFiltersComponent"
import { clientTypeVariants } from "shared/tableColVariantsList"
import { Link, useSearchParams } from "react-router-dom"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import moment from "moment"
import * as XLSX from "xlsx"
import { useQuery } from "react-query"
const Arrears = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("arrear.title")}
			</div>
		)
	}, [i18n.language])

	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [dataLoading, setDataLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [expanded, setExpanded] = useState(false)
	
	const handleGetData = () => {
		setDataLoading(true)
		let entries = Object.fromEntries(searchParams)
		let values = { from: entries?.from, till: entries?.till, object_id: entries?.object, block_id: entries?.block }
		axiosPrivate
			.get("/debtors/1",{
				responseType: "blob"
			})
			
			.then((res) => {
				saveAs(res.data, "Qarzdorlik.xlsx");
				if (res && res.data) {
					let rows1 = res.data?.map((item) => ({
						"To'lov raqami": item?.id,
						"Shartnoma raqami": item?.object_name === null? "-": item?.object_name,
						"Mas'ul": item?.who_made,
						Sana: moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						"To'lov turi": item?.payment_type,
						// t(
						// 	paymentTypeSelectOptions.find(
						// 		(paymentType) => paymentType.code === item?.type_id
						// 	)?.label
						// ),
						"To'lov maqsadi": item?.reason_name,
						"To'lov sababi": item?.comments,
						Summa: item?.sum,
						Tranzaksiya: "Kiruvchi"
					}))
					let rows = res.data?.map((item) => [
						item?.id,
						item?.object_name === null? "-": item?.object_name,
						item?.who_made,
						moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						// "",
						item?.payment_type,
						// t(
						// 	paymentTypeSelectOptions.find(
						// 		(paymentType) => paymentType.code === item?.type_id
						// 	)?.label
						// ),
						item?.reason_name,
						item?.comments,
						item?.sum,
						// "",
						"Chiquvchi"
					])
					rows.unshift([
						"Shartnoma raqami",
						"Mijoz",
						"Telefon",
						"Qolgan qarz",
						"Oylik qarz",
						"Kechikish kuni",
						"Sana",
						"Peniya",
						"Ma'sul hodim"
					])
					let worksheet = XLSX.utils.aoa_to_sheet(rows)
					// let worksheet = XLSX.utils.json_to_sheet(rows1)
					// let maxWidthArr = Object.keys(rows[0]).map((item) => {
					// 	return {
					// 		wch: rows
					// 			.map((r) => r[item])
					// 			.reduce((acc, curr) => {
					// 				return Math.max(acc, curr.toString().length)
					// 			}, 15)
					// 	}
					// })
					// worksheet["!cols"] = maxWidthArr
					let maxWidthArr = Object.keys(rows1[0]).map((item) => {
						return {
							wch:
								rows1
									.map((r) => r[item])
									.reduce((acc, curr) => {
										return Math.max(acc, curr.toString().length)
									}, 15)
						}
					})
					// console.log("maxWidthArr = ", maxWidthArr)
					worksheet["!cols"] = maxWidthArr
					// console.log("rows = ", rows)

					let fmt = "#,##0.00 [$UZS]"
					let range = { s: { r: 1, c: 6 }, e: { r: rows.length - 1, c: 6 } }
					console.log(range);
					for (let R = range.s.r; R <= range.e.r; ++R) {
						for (let C = range.s.c; C <= range.e.c; ++C) {
							let cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })]
							if (!cell || cell.t !== "n") continue
							cell.z = fmt
						}
					}

					// XLSX.utils.sheet_add_aoa(worksheet, [["Test"]], {
					// 	origin: "F23"
					// })

					let workbook = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(workbook, worksheet, t("payment.title"))
					// worksheet.F23 = { t: "s", v: "Test" }
					XLSX.writeFile(
						workbook,
						`Chiqim.xlsx`,
						{
							type: "array",
							bookType: "xlsx"
						}
					)
				}
			})
			.catch((err) => {
				console.error(err)
			})
			.finally(() => setDataLoading(false))
	}

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full">
						<Button
							variant="filterOutlined"
							color="primary"
							startIcon={<i className="bi bi-filter" />}
							className="!mr-2"
							onClick={() => setExpanded((prev) => !prev)}
						>
							{t("common.button.filter")}
						</Button>
						<SearchInput inputKey="name" />
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="primary"
							className="!mr-2"
							onClick={() => handleGetData()}
							disabled={dataLoading}
						>
							{dataLoading ? (
								<div className="flex items-center justify-center">
									<CircularProgress size={17.5} color="inherit" />
								</div>
							) : (
								<i className="bi bi-download" />
							)}
						</Button>
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							className="!mr-2"
							disable={`${refetch}`}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
				{expanded && (
					<div className="my-shadow-2 rounded-lg px-4 w-full mt-2">
						<ContractFiltersComponent />
					</div>
				)}
			</div>

			<div className="component-table-wrapper">
				<SimpleSearchTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath="dictionary/debtors"
					emitTableColumns={{ open, setOpen }}
					tableName="arrears"
					headCells={[
						{ code: "contract_name", label: t("common.table.contractName") },
						{ code: "client_type", label: t("common.table.clientType") },
						{ code: "phone", label: t("common.table.phone") },
						{ code: "left", label: t("common.table.arrear") },
						{ code: "monthleft", label: t("contract.view.header.monthlyLeft") },
						{ code: "peniya", label: t("common.table.penalty") },
						{ code: "date", label: t("common.table.date") },
						{ code: "staff", label: t("common.table.staff") }
					]}
					columns={[
						{ code: "contract_name" },
						{
							code: "custom",
							type: "nestedChain",
							childStrings: ["surname", "name", "middlename"]
						},
						{
							code: "custom",
							type: "nestedChain",
							childStrings: ["phone"]
						},
						{ code: "left", type: "priceCurrency" },
						{ code: "oylikqarz", type: "priceCurrency" },
						{ code: "peniya", type: "priceCurrency" },
						{ code: "date", type: "date" },
						{ code: "staff", type: "nested", childStr: "name" }
					]}
					actionContractView
				/>
			</div>
		</div>
	)
}

export default Arrears
