import { Grid } from "@mui/material"
import React from "react"
import SingleBlockFilter from "./items/SingleBlockFilter"
import SingleObjectFilter from "./items/SingleObjectFilter"
import CuntractNumberFilter from './items/ContractNumberFilter'
import StatusFilter from "./items/StatusFilter"
import HomeNumberFilter from "./items/HomeNumberFilter"
import { useFormik } from "formik"
const ContractFiltersComponent = ({status}) => {
	const formik = useFormik({
		initialValues: {
			sum: "",
			payment_type: "",
			comment: "",
			object_id: "",
			block_id: ""
		},
	
	})
	const handleObjectChange = (value) => {
		formik.setFieldValue("object_id", value, true)
		formik.setFieldValue("block_id", "", true)
	}
	return (
		<div className="mb-1 pb-2">
			<Grid
				container
				spacing={{ xs: 2, sm: 2, lg: 2 }}
				rowSpacing={1}
				columns={{ xs: 12, sm: 12, lg: 12 }}
			>
				<Grid item={true} lg={2} sm={6} xs={12}>
					<CuntractNumberFilter />
				</Grid>
				<Grid item={true} lg={2} sm={6} xs={12}>
					<HomeNumberFilter />
				</Grid>
				<Grid item={true} lg={2} sm={6} xs={12}>
					<SingleObjectFilter
					fieldName="object_id"
					path="/dictionary/objects2" 
					formik={formik}
					changeFn={handleObjectChange}
					/>
				</Grid>
				{formik.values.object_id && (
				<Grid item={true} lg={2} sm={6} xs={12}>
					<SingleBlockFilter 
					fieldName="block_id"
					formik={formik}
					path={`admin/block/index/${formik.values.object_id}`}
					pathChangeable={true} />
				</Grid>
				)}
				{status && 
				<Grid item={true} lg={2} sm={6} xs={12}>
					<StatusFilter />
				</Grid>
				}
			</Grid>
		</div>
	)
}

export default ContractFiltersComponent
